@use '@angular/material' as mat;
.ub-heading-1,
.ub-heading-2,
.ub-heading-3,
.ub-heading-4 {
  margin: 8px 0;
}

.ub-heading-1 {
  $config: (
    heading1: mat.m2-define-typography-level($headline-2...)
  );

  @include mat.m2-typography-level($config, heading1);
}

a:link,
a:visited {
  color: mat.m2-get-color-from-palette($primary, 500);
}

.hl-primary,
.hl-accent,
.hl-warn,
.hl-success,
.hl-danger {
  color: #fff;
  padding: 0 3px;
}

.hl-primary {
  background-color: var(--color-primary, #2425a0);
}

.hl-accent {
  background-color: var(--color-accent, #247ba0);
}

.hl-warn {
  background-color: var(--color-warn, #ffbc08);
}

.hl-success {
  background-color: var(--color-success, #0c9612);
}

.hl-danger {
  background-color: var(--color-danger, #ff1616);
}
