/* autoprefixer grid: on */

/* You can add global styles to this file, and also import other style files */

/* @import "@angular/material/prebuilt-themes/indigo-pink.css"; */
/* @import '@angular/material/prebuilt-themes/pink-bluegrey.css'; */
/* @import '@angular/material/prebuilt-themes/deeppurple-amber.css'; */
/* @import '@angular/material/prebuilt-themes/purple-green.css'; */

@import 'themes/eas.scss';
@import 'quill/dist/quill.snow.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  background: #f0f0f0;
}

.center {
  width: 75%;
  margin: 10px auto;
}

.main-div {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.debug {
  background-color: #ccc;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #444;
  font-size: 0.9em;
  padding: 4px 8px;
}

a:hover {
  cursor: pointer;
}

.blurred {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
  filter: blur(4px);
}

.form-container {
  display: flex;
  flex-direction: column;

  mat-form-field {
    width: 100%;
  }
}

fieldset {
  border: 0;
  margin: 0.8em 0;
  padding-left: 0;
  padding-right: 0;
}

fieldset legend {
  background-color: var(--color-gray-lightest);
  border: 1px solid var(--color-gray);
  border-radius: 2px;
  padding: 0.8em 0.4em;
  width: calc(100% - 16px);
}

fieldset legend > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

fieldset legend > div > span {
  color: #0d0d58;
  flex-basis: 70%;
  font-weight: 500;
}

fieldset legend > div > div {
  align-items: center;
  display: flex;
  flex-basis: 30%;
  justify-content: flex-end;
}

fieldset legend > div > div a {
  margin-left: 0.8em;
}

#app-main {
  display: flex;
  flex-direction: column;
  padding: 1.6em;
}

#app-footer {
  margin: 1.6em 0;
}

#content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main-col, // this is a very dumb name
  .list,
  aside {
    flex: 0 0 100%;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    .main-col, // this is a very dumb name
    .list {
      flex-basis: 75%;
    }

    aside {
      flex-basis: calc(25% - 16px); // we want a margin between the two flex items
    }
  }
}

// FIXME: We can better implement grid in Unboxed, we use flexbox for the time being
// @supports (display: grid) {
//   #content {
//     display: grid;

//     grid-template-columns: 1fr;

//     grid-gap: 16px;
//     grid-template-areas: "eas-list eas-aside";

//     @media screen and (min-width: 768px) {
//       -ms-grid-columns: 2fr 16px 1fr;
//       grid-template-columns: 2fr 1fr;
//     }
//   }

//   .list {
//     grid-area: eas-list;
//   }

//   aside {
//     grid-area: eas-aside;
//   }
// }

label .required {
  font-weight: bold;
}
/*
// The following style was used to allow multiline selects, but it's not compatible with Angular Material calculations

.mat-autocomplete-panel .mat-option,
.mat-select-panel .mat-option {
  height: auto !important;
  line-height: normal !important;
  padding: 8px 16px;
}

.mat-option-text {
  white-space: normal !important;
}
*/

/* TODO: For Andrea, keep in global and remove this comment */
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-justify { text-align: justify; }

.small { font-size: 80%; }
.text-muted { color: var(--color-gray); }
.text-success { color: var(--color-success); }

.font-weight-normal { font-weight: normal; }
.font-weight-bold { font-weight: bold; }

[class*="mb-"] { margin-bottom: calc(var(--grid-spacer-increments) * var(--gap)); }
[class*="mt-"] { margin-top: calc(var(--grid-spacer-increments) * var(--gap)); }
[class*="pb-"] { padding-bottom: calc(var(--grid-spacer-increments) * var(--gap)); }
[class*="pt-"] { padding-top: calc(var(--grid-spacer-increments) * var(--gap)); }
.pt-1, .pb-1, .mt-1, .mb-1 { --gap: 1; }
.pt-2, .pb-2, .mt-2, .mb-2 { --gap: 2; }
.pt-3, .pb-3, .mt-3, .mb-3 { --gap: 3; }
.pt-4, .pb-4, .mt-4, .mb-4 { --gap: 4; }
.pt-5, .pb-5, .mt-5, .mb-5 { --gap: 5; }

.justify-content-start { justify-content: flex-start; }
.justify-content-center { justify-content: center; }
.justify-content-end { justify-content: flex-end; }

.list-unstyled {
  list-style: none inside none;
  margin: 0;
  padding: 0;
}

.list-inline li {
  display: inline-block;
  margin-right: 0.32em;
}

.list-inline li:last-of-type {
  margin-right: 0;
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--grid-gap);
  padding-right: var(--grid-gap);
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--grid-gap);
  margin-right: var(--grid-gap);
}

.col {
  flex: 1 1 0;
  max-width: 100%;
}

.col,
[class^="col-"] {
  --col-width: 12; /* By default, all are 100% */

  min-height: 1px;
  padding-left: var(--grid-gap);
  padding-right: var(--grid-gap);
  position: relative;
  width: 100%;
}

[class^="col-"] {
  flex: 0 0 calc(var(--col-width) / var(--grid-cols) * 100%);
  max-width: calc(var(--col-width) / var(--grid-cols) * 100%);
}

@media screen and (min-width: 48em) {
  /* At larger sizes, we switch to having columns */
  .col-1 { --col-width: 1; }
  .col-2 { --col-width: 2; }
  .col-3 { --col-width: 3; }
  .col-4 { --col-width: 4; }
  .col-5 { --col-width: 5; }
  .col-6 { --col-width: 6; }
  .col-7 { --col-width: 7; }
  .col-8 { --col-width: 8; }
  .col-9 { --col-width: 9; }
  .col-10 { --col-width: 10; }
  .col-11 { --col-width: 11; }
  .col-12 { --col-width: 12; }

  /* Because math is hard and developers shouldn't count columns */
  .col-half { --col-width: calc(var(--grid-cols) / 2); }
  .col-third { --col-width: calc(var(--grid-cols) / 3); }
  .col-fourth { --col-width: calc(var(--grid-cols) / 4); }
}

/* TODO: For Andrea, these can all be moved to the booking item editor module */
:root {
  --green: #28a745;
  --orange: #fd7e14;
  --red: #dc3545;
  --blue: #007bff;
  --light: #f8f9fa;
  --dark: #343a40;

  --color-gray: #89898d;
  --color-gray-lightest: #dee2e6;

  --color-set: var(--green);
  --color-inherited: var(--orange);
  --color-overridden: var(--red);
  --color-empty: var(--color-gray-lightest);

  --color-success: var(--green);
  --color-warning: var(--orange);
  --color-danger: var(--red);
  --color-info: var(--blue);
  --color-neutral: var(--color-gray);

  --color-success-opacity-5: rgba(40, 167, 69, 0.05);
  --color-warning-opacity-5: rgba(253, 126, 20, 0.05);
  --color-danger-opacity-5: rgba(220, 53, 69, 0.05);
  --color-info-opacity-5: rgba(220, 123, 255, 0.05);
  --color-neutral-opacity-5: rgba(68, 68, 68, 0.05);

  --size-gap: 0.8em;
  --grid-gap: calc(var(--size-gap) * 2);
  --grid-cols: 12;
  --grid-spacer-increments: 0.25em;
}

.table {
  background-color: transparent;
  border-collapse: collapse;
  max-width: 100%;
  width: 100%;
}

.table td,
.table th {
  border-top: 1px solid var(--color-gray-lightest);
  padding: var(--size-gap);
  vertical-align: top;
}

.table-sm td,
.table-sm th {
  padding: calc(var(--size-gap) / 2);
}

.table-md td,
.table-md th {
  padding: var(--size-gap);
}

.table-lg td,
.table-lg th {
  padding: calc(var(--size-gap) * 1.5);
}

.option_row--empty {
  background-color: transparent;
}

.option_row--set {
  background-color: var(--color-success-opacity-5);
}

.option_row--inherited {
  background-color: var(--color-warning-opacity-5);
}

.option_row--overridden {
  background-color: var(--color-danger-opacity-5);
}

.option_header--set,
.option_header--inherited,
.option_header--overridden,
.option_header--empty {
  border-left: 8px solid var(--color-empty);
}

.option_header--set {
  border-left-color: var(--color-set);
}

.option_header--inherited {
  border-left-color: var(--color-inherited);
}
.option_header--overridden {
  border-left-color: var(--color-overridden);
}

.mat-mdc-card-header-actions {
  flex-grow: 1;
}

.subtitle-text {
  color: var(--dark);
}

.subtitle-help-text {
  background-color: rgba(28, 28, 122, 0.08);
  padding: 4px 8px;
  color: #0D0D58;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-left: 4px solid #0D0D58;
}

/* Nav */
.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: calc(var(--size-gap) / 2) var(--size-gap);
}

.mdc-button__label {
  white-space: normal !important;
}

/* WYSIWYG */
// Use the .wysiwyg-content class for all WYSIWYG styles with
.wysiwyg-content {
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}
