@use '@angular/material' as mat;

@import 'utils/typography';
@import 'configs/rcpch';

// From node_modules/@angular/material/_theming.scss
// 3/7/2020
// the "disabled-button" property had to be changed to: disabled-button: rgba(black, 0.7)
// For that, we need to copy everything and replace just that one
// It's commented for the moment, because not sure if it's needed
/*
mat.$light-theme-foreground-palette: (
  base:              black,
  divider:           rgba(black, 0.12),
  dividers:          rgba(black, 0.12),
  disabled:          rgba(black, 0.38),
  disabled-button:   rgba(black, 0.7),
  disabled-text:     rgba(black, 0.38),
  elevation:         black,
  hint-text:         rgba(black, 0.38),
  secondary-text:    rgba(black, 0.54),
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);
*/

$rcpch-theme: mat.m2-define-light-theme((
  color: (
    primary: mat.m2-define-palette($primary),
    accent: mat.m2-define-palette($accent, 500, 900, A100),
    warn: mat.m2-define-palette($warn),
  ),
  typography: mat.m2-define-typography-config(
    $font-family: $font-family,
    $headline-1: mat.m2-define-typography-level($headline-1...),
    $headline-2: mat.m2-define-typography-level($headline-2...),
    $headline-3: mat.m2-define-typography-level($headline-3...),
    $headline-4: mat.m2-define-typography-level($headline-4...),
    $headline-5: mat.m2-define-typography-level($headline-5...),
    $headline-6: mat.m2-define-typography-level($headline-6...),
    $subtitle-1: mat.m2-define-typography-level($subtitle-1...),
    $subtitle-2: mat.m2-define-typography-level($subtitle-2...),
    $body-1: mat.m2-define-typography-level($body-1...),
    $body-2: mat.m2-define-typography-level($body-2...),
    $caption: mat.m2-define-typography-level($caption...),
    $button: mat.m2-define-typography-level($button...)
  ),
  density: 0
));

@include eas-typography-color($h1-color, h1);
@include eas-typography-color($heading-color, h2, h3, h4, h5);

// Overrides
@import 'components/buttons', 'components/card', 'components/toolbar', 'components/typography';
