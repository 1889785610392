@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700');

// Colors
$primary: (
  50: #e2e2eb,
  100: #b6b6cd,
  200: #8686ac,
  300: #56568a,
  400: #313171,
  500: #0d0d58,
  600: #0b0b50,
  700: #090947,
  800: #07073d,
  900: #03032d,
  A100: #6666ff,
  A200: #3333ff,
  A400: #0000ff,
  A700: #0000e6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$accent: (
  50: #e0edf9,
  100: #b3d1f0,
  200: #80b3e6,
  300: #4d94db,
  400: #267dd4,
  500: #0066cc,
  600: #005ec7,
  700: #0053c0,
  800: #0049b9,
  900: #0038ad,
  A100: #d7e1ff,
  A200: #a4baff,
  A400: #7194ff,
  A700: #5881ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$warn: (
  50: #fff0e0,
  100: #ffd9b3,
  200: #ffc080,
  300: #ffa64d,
  400: #ff9326,
  500: #ff8000,
  600: #ff7800,
  700: #ff6d00,
  800: #ff6300,
  900: #ff5000,
  A100: #ffffff,
  A200: #fff5f2,
  A400: #ffcfbf,
  A700: #ffbca6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Typography
$font-family: 'Montserrat, sans-serif'; // must be in quotes
$headline-1: 54px, 54px, 400;
$headline-2: 48px, 48px, 700;
$headline-3: 32px, 32px, 700;
$headline-4: 28px, 30px, 700;
$headline-5: 26px, 30px, 700;
$headline-6: 24px, 28px, 700;
$subtitle-1: 18px, 24px, 400;
$subtitle-2: 16px, 20px, 400;
$body-2: 14px, 24px, 500;
$body-1: 14px, 20px, 400;
$caption: 12px, 20px, 400;
$button: 14px, 14px, 500;

// Typography colors
$h1-color: #0d0d58;
$heading-color: #0d0d58;

$button-border-radius: 0;
