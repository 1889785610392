@use '@angular/material' as mat;
@use '@mat-datetimepicker/core/datetimepicker/datetimepicker-theme';

@import 'unboxed';
@import 'rcpch';
@import 'risr';

@include mat.core();

// The Unboxed theme
@include mat.all-component-themes($unboxed-theme);
@include datetimepicker-theme.mat-datetimepicker-theme($unboxed-theme);

// Any additional themes can go here, as shown below
// This assumes the body tag gets the .org_rcpch class
.org_rcpch {
  @include mat.all-component-colors($risr-theme);
  @include mat.all-component-typographies($risr-theme);
  @include datetimepicker-theme.mat-datetimepicker-theme($risr-theme);

  .mat-drawer-container {
    background-color: #f0f0f0;
  }
}

// Added by Matous as I don't know how else
span.met {
  color: green;
}

span.unmet {
  color: red;
}

@import './utils/_a11y';
@import './utils/_layout';
