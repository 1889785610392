@mixin eas-typography-color($color, $selectors...) {
  @each $selector in $selectors {
    #{$selector} {
      color: #{$color};
    }
  }
}

.t-upper {
  text-transform: uppercase;
}

.t-lower {
  text-transform: lowercase;
}

.t-weight--normal {
  font-weight: normal;
}

.text-primary-50 { color: var(--rsr-color-primary-50); }
.text-primary-100 { color: var(--rsr-color-primary-100); }
.text-primary-200 { color: var(--rsr-color-primary-200); }
.text-primary-300 { color: var(--rsr-color-primary-300); }
.text-primary-400 { color: var(--rsr-color-primary-400); }
.text-primary-500 { color: var(--rsr-color-primary-500); }
.text-primary-600 { color: var(--rsr-color-primary-600); }
.text-primary-700 { color: var(--rsr-color-primary-700); }
.text-primary-800 { color: var(--rsr-color-primary-800); }
.text-primary-900 { color: var(--rsr-color-primary-900); }

.text-accent-50 { color: var(--rsr-color-accent-50); }
.text-accent-100 { color: var(--rsr-color-accent-100); }
.text-accent-200 { color: var(--rsr-color-accent-200); }
.text-accent-300 { color: var(--rsr-color-accent-300); }
.text-accent-400 { color: var(--rsr-color-accent-400); }
.text-accent-500 { color: var(--rsr-color-accent-500); }
.text-accent-600 { color: var(--rsr-color-accent-600); }
.text-accent-700 { color: var(--rsr-color-accent-700); }
.text-accent-800 { color: var(--rsr-color-accent-800); }
.text-accent-900 { color: var(--rsr-color-accent-900); }

.text-warn-50 { color: var(--rsr-color-warn-50); }
.text-warn-100 { color: var(--rsr-color-warn-100); }
.text-warn-200 { color: var(--rsr-color-warn-200); }
.text-warn-300 { color: var(--rsr-color-warn-300); }
.text-warn-400 { color: var(--rsr-color-warn-400); }
.text-warn-500 { color: var(--rsr-color-warn-500); }
.text-warn-600 { color: var(--rsr-color-warn-600); }
.text-warn-700 { color: var(--rsr-color-warn-700); }
.text-warn-800 { color: var(--rsr-color-warn-800); }
.text-warn-900 { color: var(--rsr-color-warn-900); }
