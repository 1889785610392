@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

// Colors
/* For use in src/lib/core/theming/_palette.scss */
$primary: (
  50 : #e4ebee,
  100 : #bcced5,
  200 : #8fadb9,
  300 : #628c9d,
  400 : #417488,
  500 : #1f5b73,
  600 : #1b536b,
  700 : #174960,
  800 : #124056,
  900 : #0a2f43,
  A100 : #7acbff,
  A200 : #47b6ff,
  A400 : #14a2ff,
  A700 : #0097fa,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$accent: (
  50 : #e2ebe9,
  100 : #b7ccc8,
  200 : #88aaa3,
  300 : #58887e,
  400 : #346f63,
  500 : #105547,
  600 : #0e4e40,
  700 : #0c4437,
  800 : #093b2f,
  900 : #052a20,
  A100 : #65ffcb,
  A200 : #32ffba,
  A400 : #00fea9,
  A700 : #00e598,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$warn: (
  50 : #f9e0e0,
  100 : #f1b3b3,
  200 : #e88080,
  300 : #de4d4d,
  400 : #d72626,
  500 : #d00000,
  600 : #cb0000,
  700 : #c40000,
  800 : #be0000,
  900 : #b30000,
  A100 : #ffdcdc,
  A200 : #ffa9a9,
  A400 : #ff7676,
  A700 : #ff5d5d,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// Typography
$font-family: 'DM Sans, sans-serif'; // must be in quotes
$headline-1: 54px, 54px, 400;
$headline-2: 48px, 48px, 700;
$headline-3: 32px, 32px, 700;
$headline-4: 28px, 30px, 700;
$headline-5: 26px, 30px, 700;
$headline-6: 24px, 28px, 700;
$subtitle-1: 18px, 24px, 400;
$subtitle-2: 16px, 20px, 400;
$body-2: 14px, 24px, 500;
$body-1: 14px, 20px, 400;
$caption: 12px, 20px, 400;
$button: 14px, 14px, 500;

// Typography colors
$h1-color: #001e33;
$heading-color: #001e33;

$button-border-radius: 2px;
