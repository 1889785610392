.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-none {
  object-fit: none;
}

.object-scale-down {
  object-fit: scale-down;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.size-full {
  width: 100%;
  height: 100%;
}

.min-h-50vh {
  min-height: 50vh;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}
