// This is just overwritten by Angular's inline styles
// Don't know how to fix without having a bunch of !important statements
.mat-mdc-flat-button {
  border-radius: $button-border-radius !important;
}

.button--block {
  display: block;
  width: 100%;
}
